

















.plus-button {
  background: #00ADFF;
  padding: 7px 24px;
  border-radius: 4px;
  color: #FFF;
  transition: all .2s ease-in-out;
  min-width: 129px;
  display: flex;
  justify-content: center;
  outline: none;
  font-size: 15px;
  height: 32px;

  &:hover {
    background: #01a0ea;
  }

  div {
    margin-top: -3px;
  }
}
