


























































































.modal-input-label {
  margin-bottom: 24px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #1D293F;
}
